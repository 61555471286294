<template>
  <googlemaps-map
    ref="map"
    :center.sync="center"
    :zoom.sync="zoom"
    class="md:w-full md:h-272 xl:h-800 xl:w-full"
    :options="options"
  >
    <googlemaps-marker
      v-for="marker of state.items"
      @click="goDoctor(marker.objectID)"
      :key="marker.objectID"
      :icon="avatarUser(marker)"
      :title="`${marker.firstName} ${marker.lastName}`"
      :position="marker._geoloc"
      :opacity="1"
      :animation="2"
    ></googlemaps-marker>
  </googlemaps-map>
</template>

<style>
.gm-style img {
  @apply rounded-lg border-2 border-solid border-black;
}

.gm-style-iw {
  width: 350px !important;
  top: 0 !important;
  left: 0 !important;
  border-radius: 2px 2px 0 0;
}
</style>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["state"],
  data() {
    return {
      zoom: 12,
      center:
        this.state.items[0] && this.state.items[0]._geoloc
          ? this.state.items[0]._geoloc
          : { lat: 31.628674, lng: -7.992047 },
      options: {
        disableDefaultUI: true
      }
    };
  },
  watch: {
    state(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Scroll To Top
      }
      if (Object.keys(newVal.items).length) {
        for (let index = 0; index < Object.keys(newVal.items).length; index++) {
          const client = newVal.items[index];
          new google.maps.LatLng(client._geoloc.lat, client._geoloc.lng);
        }
      }
    }
  },
  methods: {
    avatarUser(user) {
      return {
        url: this.$checkAvatar(user.image),
        scaledSize: new google.maps.Size(45, 45),
        size: new google.maps.Size(75, 75),
        optimized: true
      };
      return;
    },
    goDoctor(DocID) {
      EventBus.$emit("clickMarker", DocID);
    }
  }
};
</script>
